<template>
  <div>
    <header-slot />
    <b-nav
      card-header
      pills
      class="m-0"
    >
      <b-nav-item
        :to="{ name: 'credit-experts-migrations-list-pending' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3',bgTabsNavs]"
      >Pending
				<span class="ml-2">
					<span v-if="COUNT_MIGRATIONS_PENDING > 0">
						<feather-icon
							icon
							:badge="COUNT_MIGRATIONS_PENDING"
							badge-classes="badge-danger badge-glow"
						/>
					</span>
				</span>
      </b-nav-item>
      <b-nav-item
        :to="{ name: 'credit-experts-migrations-list-accepted' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3',bgTabsNavs]"
      >Accepted</b-nav-item>
      <b-nav-item
        :to="{ name: 'credit-experts-migrations-list-rejected' }"
        exact
        exact-active-class="active"
        :link-classes="['px-3',bgTabsNavs]"
      >Rejected</b-nav-item>
    </b-nav>

    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <router-view :key="$route.name" />
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	computed: {
    ...mapGetters({
      COUNT_MIGRATIONS_PENDING: "CreditExpertsMigrationsStore/G_COUNTER_MIGRATIONS_PENDING"
    })
	},
}
</script>

<style>
</style>